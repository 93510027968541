import Keycloak from 'keycloak-js';
import utility from '@/utils/utility';

const keycloak = new Keycloak({
	url: utility.getEnv('VUE_APP_KEYCLOAK_URL'),
	clientId: utility.getEnv('VUE_APP_KEYCLOAK_CLIENT_ID') as string,
	realm: utility.getEnv('VUE_APP_KEYCLOAK_REALM') as string
});

keycloak.onTokenExpired = async (): Promise<void> => {
	await keycloak
		.updateToken(30)
		.then(() => {
			// perform required actions on token refresh, e.g. for live subscriptions
		})
		.catch((err) => {
			console.error('failed to refresh token', err);
		});
};

const signin = async (): Promise<void> => {
	await keycloak.init({
		onLoad: 'login-required',
		flow: 'standard',
		checkLoginIframe: false,
		redirectUri: window.location.href
	});
};

const signout = (): void => {
	keycloak.logout();
};

const getToken = (): string => {
	const {token} = keycloak;
	if (!token) {
		throw new Error('could not resolve keycloak token');
	}
	return token;
};

const getUserId = (): string => {
	const sub = keycloak.idTokenParsed?.sub;
	if (!sub) {
		throw new Error('could not resolve subject from token');
	}
	return sub;
};

const getUserName = (): string => {
	return `${(keycloak as any).idTokenParsed.given_name} ${(keycloak as any).idTokenParsed.family_name}`;
};

export default {
	signin,
	signout,
	getToken,
	getUserId,
	getUserName
};
