const DEV = 'kuka-robot-selector.test.centersight.io';
const environment: {[key: string]: string | undefined} = process.env;

const isTest = () => {
	const hostname = window.location.hostname.toLowerCase();
	return getEnvBool('VUE_APP_TEST_MARKER', false) && (hostname === DEV || isLocal());
};

const isLocal = () => {
	const hostname = window.location.hostname.toLowerCase();
	return hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '0.0.0.0';
};

const fillHostname = (url: string) => url.replace('{hostname}', isLocal() ? DEV : window.location.hostname);

const getEnv = (key: string, fallback?: string): string | undefined => {
	if ((window as any).env && (window as any).env[key]) {
		return (window as any).env[key] as string;
	} else {
		const value = environment[key];
		if (value !== undefined) {
			return value;
		} else {
			console.error(`Env variable ${key} not set`);
		}
		return fallback;
	}
};

const getEnvBool = (key: string, fallback?: boolean): boolean => {
	const value = getEnv(key, fallback?.toString());
	return value === 'true';
};

const getHostname = (): string => {
	return isLocal() ? DEV : window.location.hostname;
};

export default {
	isTest,
	fillHostname,
	getHostname,
	getEnv,
	getEnvBool
};
